export const m = {
  // 英语语言包
  app: {
    login: 'login',
    'Home': 'Home',
    'Nearby': 'Nearby',
    'Message': 'Message',
    'Mypage': 'Mypage',
  },
  common: {
    // 暂无数据
    'NoData': 'No Data',
    // 敬请期待
    'ComingSoon': 'Coming Soon',
    // 请重新登录
    'PleaseLoginAgain': 'Please Login Again',
    // 登录已过期，请重新登录
    'LoginExpiredPleaseLoginAgain': 'Login Expired, Please Login Again',
    // 请在小程序中打开
    'PleaseOpenInMiniProgram': 'Please open in mini program',
    // 复制成功
    'copySuccess': 'copy success',
    // 复制失败
    'copyFail': 'copy fail',
  },
  home: {
    'readMore': 'Read More',
    'hotelEventOverview': 'Hotel Event Overview',
    // 暂无公告
    'NoAnnouncement': 'No Announcement',
    // 取消
    'Cancel': 'Cancel',
  },
  accountHome: {
    welcome: 'welcome 🎉',
    Login: 'Login',
    Register: 'Register',
    ContinueAsAGuest: 'Continue as a guest',
  },
  login: {
    'WelcomeBackGladToSeeYouAgain': 'Welcome back! Glad to see you, Again!',
    // Enter your email
    'EnterYourEmail': 'Enter your email',
    // Enter your password
    'EnterYourPassword': 'Enter your password',
    // 请输入正确的邮箱地址
    'PleaseEnterTheCorrectEmailAddress': 'Please enter the correct email address',
    // Forgot Password?
    'ForgotPassword': 'Forgot Password?',
    // Don’t have an account?
    'DontHaveAnAccount': 'Don’t have an account?',
    // Register Now
    'RegisterNow': 'Register Now',
    // Login
    'Login': 'Login',
  },
  register: {
    'WelcomeBackGladToSeeYouAgain': 'Welcome back! Glad to see you, Again!',
    // Nickname
    'Nickname': 'Nickname',
    // Email
    'Email': 'Enter your email',
    // 请输入正确的邮箱地址
    'PleaseEnterTheCorrectEmailAddress': 'Please enter the correct email address',
    // Password
    'Password': 'Enter your password',
    // 两次输入的密码不一致
    'PasswordsAreInconsistent': 'Passwords are inconsistent',
    // Confirm password
    'ConfirmPassword': 'Confirm password',
    // Agree and Register
    'AgreeAndRegister': 'Register',
    // 发送验证码
    'SendCode': 'Send Code',
    // 验证
    'Verify': 'Verify',
    // 注册用户
    'RegisteredUsers': 'Registered Users',
    // OTP Verification
    'OTPVerification': 'OTP Verification',
    // Enter the verification code we just sent on your email address.
    'EnterTheVerificationCodeWeJustSentOnYourEmailAddress': 'Enter the verification code we just sent on your email address.',
    // 请填写密码
    'PleaseEnterYourPassword': 'Please enter your password',
    // 将会作为您的登录密码
    'WillBeUsedAsYourLoginPassword': 'Will be used as your login password',
    // RegisteredSuccessfully
    'RegisteredSuccessfully': 'Registered successfully',
  },
  sso: {
    // Or Login with
    'OrLoginWith': 'Or Login with',
  },
  forgotPassword: {
    // Forgot Password
    'ForgotPassword': 'Forgot Password?',
    // Don't worry! It occurs. Please enter the email address linked with your account.
    'DontWorryItOccursPleaseEnterTheEmailAddressLinkedWithYourAccount': 'Don\'t worry! It occurs. Please enter the email address linked with your account.',
    // Enter your email
    'EnterYourEmail': 'Enter your email',
    // Send Code
    'SendCode': 'Send Code',
    // Remember Password?
    'RememberPassword': 'Remember Password?',
    // Login
    'Login': 'Login',
    // OTP Verification
    'OTPVerification': 'OTP Verification',
    // Enter the verification code we just sent on your email address.
    'EnterTheVerificationCodeWeJustSentOnYourEmailAddress': 'Enter the verification code we just sent on your email address.',
    // Verify
    'Verify': 'Verify',
    // Didn’t received code?
    'DidntReceivedCode': 'Didn’t received code?',
    // Resend
    'Resend': 'Resend',
    // Create new password
    'CreateNewPassword': 'Create new password',
    // Your new password must be unique from those previously used.
    'YourNewPasswordMustBeUniqueFromThosePreviouslyUsed': 'Your new password must be unique from those previously used.',
    // New Password
    'NewPassword': 'New Password',
    // Confirm Password
    'ConfirmPassword': 'Confirm Password',
    // Reset Password
    'ResetPassword': 'Reset Password',
    // 请输入正确的邮箱地址
    'PleaseEnterTheCorrectEmailAddress': 'Please enter the correct email address',
    // 请输入密码
    'PleaseEnterYourPassword': 'Please enter your password',
    // 两次输入的密码不一致
    'PasswordsAreInconsistent': 'Passwords are inconsistent',
    // 发送成功
    'SendSuccessfully': 'Send successfully',
  },
  passwordChanged: {
    // Password Changed!
    'PasswordChanged': 'Password Changed!',
    // Your password has been changed successfully.
    'YourPasswordHasBeenChangedSuccessfully': 'Your password has been changed successfully.',
    // Back to Login
    'BackToLogin': 'Back to Login',
  },
  FeedBack: {
    // Name
    'Name': 'Name',
    // Please enter the English name on the passport!
    'PleaseEnterTheEnglishNameOnThePassport': 'Please enter the English name on the passport!',
    // Telphone
    'Telphone': 'Telphone',
    // lease enter your phone number
    'LeaseEnterYourPhoneNumber': 'lease enter your phone number',
    // Email
    'Email': 'Email',
    // Please enter your e-mail address
    'PleaseEnterYourEMailAddress': 'Please enter your e-mail address',
    // 请输入正确的邮箱地址
    'PleaseEnterTheCorrectEmailAddress': 'Please enter the correct email address',
    // Check-in
    'CheckIn': 'Check-in ',
    // Please select a check-in date
    'PleaseSelectACheckInDate': 'Please select a check-in date',
    // Feedback content
    'FeedbackContent': 'Feedback content',
    // 请输入反馈内容
    'PleaseEnterYourFeedbackContent': 'Please enter your feedback content',
    // 提交成功
    'SubmittedSuccessfully': 'Submitted successfully',
    // Please enter your comments and suggestions, and you can also contact the hotel through here if the item is lost.
    'PleaseEnterYourCommentsAndSuggestionsAndYouCanAlsoContactTheHotelThroughHereIfTheItemIsLost': 'Please enter your comments and suggestions, and you can also contact the hotel through here if the item is lost.',
    // Sent
    'Sent': 'Sent',
  },
  navigation: {
    // Save to album
    'SaveToAlbum': 'Save',
    // Navigate to the hotel
    'NavigateToTheHotel': 'Navigate to the hotel',
    // ※可长按保存
    'LongPressToSave': '※Long press to save',
  },
  CouponFormat: {
    // See details
    'SeeDetails': 'See details',
    // Get
    'Get': 'Get',
    // Use
    'Use': 'Use',
    // 永久有效
    'PermanentValidity': 'Permanent validity',
    // 有效期至：${item.endTimeDisplay}
    'ValidUntil': 'Valid until: {endTimeDisplay}',
    // 有效天数：${item.effeDays}天
    'ValidDays': 'Valid days: {effeDays} days',
    // 结束时间：${item.endTimeDisplay}
    'EndTime': 'End time: {endTimeDisplay}',
    // 使用时间：${item.useTimeDisplay}
    'UseTime': 'Use time: {useTimeDisplay}',
    // `每人限领：${item.limit}次`
    'Limit': 'Limit: {limit} times',
  },
  shopList: {
    // JPY
    'JPY': 'JPY',
    // RMB
    'RMB': 'RMB',
  },
  barCart: {
    // JPY
    'JPY': 'JPY',
    // Next
    'Next': 'Next',
  },
  userCenter: {
    // Coupon
    'Coupon': 'Coupon',
    // Membership
    'Membership': 'Membership',
    // Collection
    'Collection': 'Collection',
    // My Order
    'MyOrder': 'My Order',
    // Coupon Center
    'CouponCenter': 'Coupon Center',
    // Settings
    'Settings': 'Settings',
    // Service
    'Service': 'Service',
    // Product review
    'ProductReview': 'Product review',
    // About QR SKI
    'AboutQRMALL': 'About QR SKI',
    // Logout
    'Logout': 'Logout',
    // 我的收藏
    'MyCollection': 'My Collection',
    // 我的优惠券
    'MyCoupon': 'My Coupon',
  },
  eventList: {
    // 查看详情
    'SeeDetails': 'More',
  },
  collectionList: {
    // 访问足迹
    'AccessFootprint': 'Access Footprint',
    // 我的收藏
    'MyCollection': 'My Collection',
    // 点击查看〉
    'ClickToView': 'Click to view 〉',
    // 确认
    'Confirm': 'Confirm',
    // 确定要跳转吗？
    'ConfirmToJump': 'Confirm to jump?',
  },
  myCouponList: {
    // 可使用
    'Available': 'Available',
    // 已过期
    'Expired': 'Expired',
    // 已使用
    'Used': 'Used',
    // 没有更多啦~
    'NoMore': 'No more',
    // 即将过期
    'Expiring': 'Expiring',
    // 去使用
    'GoToUse': 'Go to use',
    // 领取
    'Get': 'Get',
  },
  NotFound: {
    // 信息获取失败
    'HotelInformationFailedToGet': 'Information failed to get',
    // 查看介绍
    'ViewIntroduction': 'View Introduction',
  },
  Article: {
    // 营业时间
    'BusinessHours': 'Business Hours',
    // 介绍
    'Introduction': 'Introduction',
  },
  DiscoverDetail: {
    // 问路卡
    'AskForTheWayCard': 'Ask for the way card',
    // 导航
    'Navigation': 'Navi',
    // 简介
    'Introduction': 'Introduction',
    // 基本信息
    'BasicInformation': 'Basic Information',
    // 地址
    'Address': 'Address',
    // 营业时间
    'BusinessHours': 'Business Hours',
    // 本页信息由第三方来源提供，不保证信息及时有效
    'FooterTip': 'The information on this page is provided by a third party and is not guaranteed to be timely and effective.',
    // （人均消费）
    'AverageConsumption': '(Average consumption)',
  },
  Coupon: {
    // 商家专属
    'MerchantExclusive': 'Merchant',
    // 平台通用
    'PlatformGeneric': 'Platform',
    // 10%免税
    '10DutyFree': '10% Duty Free',
    // 特定店铺
    'SpecificStore': 'Specific Store',
    // 去使用
    'GoToUse': 'Use',
    // 去领取
    'GoToGet': 'Get',
    // 去查看
    'GoToView': 'View',
  },
  CouponDetail: {
    // 10%免税
    '10DutyFree': '10% Duty Free',
    // 优惠券详情
    'CouponDetail': 'Coupon Detail',
    // 特定店铺
    'SpecificStore': 'Specific Store',
    // 使用方法
    'UseMethod': 'Use Method',
    // 使用须知
    'UseNotice': 'Use Notice',
    // 本优惠券为第三方搜集，进店时请与店员做好沟通，本程序不承担连带责任
    'Disclaimer': 'This coupon is collected by a third party. Please communicate with the clerk when entering the store. This program does not bear joint and several liability.',
    // 已过期
    'Expired': 'Expired',
    // 已使用
    'Used': 'Used',
    // 请让雪场服务人员点击，自己点击无效，敬请谅解
    'PleaseLetTheHotelStaffClickItYourself': 'Please let the hotel staff click, clicking by yourself is invalid, please understand',
    // 由服务人员使用
    'UsedByTheServiceStaff': 'Used by the service staff',
    // 领取
    'Get': 'Get',
    // 确认
    'Confirm': 'Confirm',
    // 确认要使用此优惠券吗？
    'ConfirmToUseThisCoupon': 'Confirm to use this coupon?',
    // 请登录
    'PleaseLogin': 'Please login',
  },
  Cropper: {
    // 裁切
    'Crop': 'Crop',
  },
  UserSetting: {
    // 请输入昵称
    'PleaseEnterYourNickname': 'Please enter your nickname',
    // 手机号
    'PhoneNumber': 'Phone Number',
    // 请输入您的手机号
    'PleaseEnterYourPhoneNumber': 'Please enter your phone number',
    // 保存
    'Save': 'Save',
  },
  AiChat: {
    // 觉得答案如何？
    'HowDoYouFeelAboutTheAnswer': 'How do you feel about the answer?',
    // 感谢您的回馈！
    'ThankYouForYourFeedback': 'Thank you for your feedback!',
    // 按住 说话
    'PressAndSpeak': 'Press and speak',
    // 发送
    'Send': 'Send',
    // 松开 结束
    'ReleaseEnd': 'Release end',
    // 最多支持${queryLimit.value}个字的发送
    'MaximumSupportForSendingWords': 'Maximum support for sending {queryLimit} words',
    // 连接发生错误
    'ConnectionError': 'Connection error',
    // 你好，我是${res.data.name}的小助手
    'HelloIAmTheAssistant': 'Hello, I am {name}\'s assistant',
    // ${merchatData.name}的AI小助手为您服务
    'AIAssistantForMerchat': 'AI assistant for {name}',
    // 无法查询到设施信息，请重新扫码
    'UnableToQueryFacilityInformationPleaseScanAgain': 'Unable to query facility information, please scan again',
    // 该设施已暂停咨询服务，请重新扫码
    'TheFacilityHasSuspendedConsultingServicesPleaseScanAgain': 'The facility has suspended consulting services, please scan again',
    // 查询设施信息失败，请稍后再试
    'FailedToQueryFacilityInformationPleaseTryAgainLater': 'Failed to query facility information, please try again later',
    // 以下是一些常见问题，请点击您想问的问题，确认答案。
    'TheFollowingAreSomeCommonQuestionsPleaseClickOnTheQuestionYouWantToAskToConfirmTheAnswer': 'The following are some common questions, please click on the question you want to ask to confirm the answer.',
    // 语音识别初始化失败，请检查麦克风权限
    'VoiceRecognitionInitializationFailedPleaseCheckTheMicrophonePermission': 'Voice recognition initialization failed, please check the microphone permission',
    // 很抱歉，可以更详细的描述您的问题吗？
    'SorryCanYouDescribeYourProblemInMoreDetail': 'Sorry, can you describe your problem in more detail?',
    // 开启新的对话
    'StartNewConversation': 'Start a new conversation',
    'NewConversation': 'New Conversation',
    // 今天
    'Today': 'Today',
    // 最近7天
    'Last7Days': 'Last 7 days',
    // 最近30天
    'MoreThan7Days': 'More than 7 days',
    // 30天前
    'MoreThan30Days': 'More than 30 days',
  },
  TranslationHelper: {
    // 说英语
    'SayLang': 'English',
    // 说日语
    'SayJp': 'Japanese',
    // 按住 说话
    'PressAndSpeak': 'Press and speak',
    // 松开 结束
    'ReleaseEnd': 'Release end',
    // 语音识别初始化失败，请检查麦克风权限
    'VoiceRecognitionInitializationFailedPleaseCheckTheMicrophonePermission': 'Voice recognition initialization failed, please check the microphone permission',
    // 通过下方工具栏，输入文字或语音，即可进行翻译
    'EnterTextOrVoice': 'Enter text or voice through the toolbar below to translate',
    // 发送过于频繁，请稍后再试
    'SendTooFrequentlyPleaseTryAgainLater': 'Send too frequently, please try again later',
    // 连接失败，请稍后重试
    'ConnectionFailedPleaseTryAgainLater': 'Connection failed, please try again later',
  },
  ErrorPage: {
    // 该内容不支持当前语言
    '406': 'This content does not support the current language',
    // 发生错误，请稍后再试
    '500': 'An error occurred, please try again later',
  }
};